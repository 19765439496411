<template>
  <div class="colorselect">
    <div id="dialog_scene" class="dialog_scene" v-show="showMsg">
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;场景内最多允许添加3个产品，请取消其他产品后再进行添加！注：只有煎盘可以挂在墙上。
      </p>
    </div>
    <slot>
      <div class="tabbaroption">
        <div class="mask"><!-- glass --></div>
        <div class="topbox">
          <div class="inconect" @click="doUpdateScene">
            <!-- 场景 -->
            <img
              src="../assets/options/changjing.png"
              alt=""
              @click="scClick()"
            />
          </div>
          <div class="delete" @click="isdel = !isdel">
            <img src="../assets/options/delete.png" alt="" />
          </div>
        </div>
        <hr />
        <!-- <div class="allmask" v-show="isdel"></div> -->
        <!-- 分割线 -->
        <div class="selector">
          <!-- 滑动选择行1 -->
          <div class="slide">
            <div class="selected" ref="selector">
              <ul class="litseletor">
                <li
                  class="color-selected"
                  v-for="(inside_colors, index) in collectList"
                  :key="'collect_' + index"
                  :id="'collect_' + index"
                  :style="{
                    background: checkInSelect(index) ? 'rgb(0 0 0 / 10%)' : '',
                  }"
                >
                  <img
                    @click="icsClick(index)"
                    draggable="false"
                    class="ics-img"
                    :src="inside_colors.src"
                    alt=""
                  />
                  <transition name="fadeRotate">
                    <img
                      @click.stop="deleCollect(index)"
                      v-show="isdel"
                      class="del"
                      src="../assets/options/del.png"
                      alt=""
                    />
                  </transition>
                </li>
              </ul>
            </div>
            <!-- inside -->
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
//import func from 'vue-editor-bridge';
import {
  updateScene,
  loadProductToScene,
  unLoadProductFromScene,
} from "../Ledmodule/model.js";
// import collectData from "../assets/data/collect.json";

export default {
  name: "Colorselect",
  props: ["collectList", "outsideData", "insideData"],
  data() {
    return {
      isdel: false,
      inArray: [],
      positionArray: {
        Position_0: "",
        Position_1: "",
        Position_2: "",
      },
      showMsg: false,

      mousedown: false,
      startTarget: "",
    };
  },

  mounted() {
    // this.closeDel();

    updateScene(this.$store.getters.getScene);
    this.$refs.selector.addEventListener("mousedown", this.mousedown1Fn);
  },

  methods: {
    mousedown1Fn: function (e) {
      let _this = this;
      _this.mousedown = true;
      document.body.style.cursor = "grab";
      _this.startTarget = _this.$refs.selector.scrollLeft + e.pageX;
      document.addEventListener("mousemove", _this.mousemove1Fn);
      document.addEventListener("mouseup", _this.mouseup1Fn);
    },
    mousemove1Fn: function (e) {
      let _this = this;
      if (_this.mousedown) {
        let endTarget;
        endTarget = e.pageX;
        _this.$refs.selector.scrollLeft = _this.startTarget - endTarget;
        _this.isMove = true;
      }
      // _this.judgeArrow("top", _this.$refs.selector.scrollLeft);
    },
    mouseup1Fn: function (e) {
      let _this = this;
      _this.mousedown = false;
      document.body.style.cursor = "default";
      _this.isMove = false;
    },
    // 判断位置
    productPosition(position) {
      if (position === "Product_0") {
        return [0, 1, 2];
      } else if (position === "Product_1") {
        return [0, 1];
      } else if (position === "Product_2") {
        return [0, 1];
      }
    },
    deepClone1(obj) {
      var _obj = JSON.stringify(obj),
        objClone = JSON.parse(_obj);
      return objClone;
    },
    icsClick(index) {
      let data = {};
      let thatInsideData;
      let thatOutsideData;
      console.log(this.collectList)
      // 拟合数据
      data.SimLight = this.collectList[index].SimLight;
      data.SimPoint = this.collectList[index].SimPoint;
      data.color1 = this.collectList[index].color1;
      data.color2 = this.collectList[index].color2;
      if (this.collectList[index].dbclick == "none") {
        // 选颜色
        data.inside = "inside_" + this.collectList[index].insideColor;
        data.outside = "outside_" + this.collectList[index].outsideColor;
        data.product = this.collectList[index].product;
        data.position = this.productPosition(data.product);
        thatInsideData = this.deepClone1(
          this.insideData[data.inside.split("_")[1]]
        );
        thatOutsideData = this.deepClone1(
          this.outsideData[data.outside.split("_")[1]]
        );
      } else if (this.collectList[index].dbclick == "inside") {
        // 选颜色 内涂双击
        data.inside = "inside_" + this.collectList[index].insideColor;
        data.outside = "inside_" + this.collectList[index].insideColor;
        data.product = this.collectList[index].product;
        data.position = this.productPosition(data.product);
        thatInsideData = this.deepClone1(
          this.insideData[data.inside.split("_")[1]]
        );
        thatOutsideData = this.deepClone1(
          this.insideData[data.inside.split("_")[1]]
        );
      } else if (this.collectList[index].dbclick == "outside") {
        // 选颜色 外涂双击
        data.inside = "outside_" + this.collectList[index].outsideColor;
        data.outside = "outside_" + this.collectList[index].outsideColor;
        data.product = this.collectList[index].product;
        data.position = this.productPosition(data.product);
        thatInsideData = this.deepClone1(
          this.outsideData[data.outside.split("_")[1]]
        );
        thatOutsideData = this.deepClone1(
          this.outsideData[data.outside.split("_")[1]]
        );
      }

      let arrIndex = -1;
      this.inArray.forEach(function (item, idx) {
        if (item == index) {
          arrIndex = idx;
        }
      });
      if (arrIndex >= 0) {
        //产品已经是选中状态  点击取消
        for (let key in this.positionArray) {
          if (this.positionArray[key] === index) {
            this.positionArray[key] = "";
            this.inArray.splice(arrIndex, 1);
            unLoadProductFromScene(data.product, key);
            break;
          }
        }
      } else {
        var handelStatus = false;
        if (this.inArray.length <= 2) {
          for (var position in this.positionArray) {
            if (this.positionArray[position] === "") {
              //判断产品是否能放置在当前position
              if (this.checkInArray(data.position, position.split("_")[1])) {
                //如果可以  直接放置
                this.inArray.push(index);
                this.positionArray[position] = index;
                // console.log(this.inArray);
                thatInsideData.Material =
                  thatInsideData.Material + "_" + position;
                thatOutsideData.Material =
                  thatOutsideData.Material + "_" + position;
                loadProductToScene(
                  data.product,
                  position,
                  thatInsideData,
                  thatOutsideData,
                  this.collectList[index].dbclick,
                  data.SimPoint,
                  data.SimLight,
                  data.color1,
                  data.color2
                );
                console.log(data);
                handelStatus = true;
                break;
              }
            }
          }
        }

        if (handelStatus == false) {
          //谈层提示信息
          console.log("没有合适的位置");
          this.doShowMsg();
        }
      }
    },
    // 检查是否选中
    checkInSelect(index) {
      return this.inArray.includes(index);
    },
    doUpdateScene() {
      this.inArray = [];
      this.positionArray = {
        Position_0: "",
        Position_1: "",
        Position_2: "",
      };
      var nowScene = this.$store.getters.getScene;
      var newScene = "";
      if (nowScene == "Scene_0" || nowScene == "scene_0") {
        newScene = "Scene_1";
      } else {
        newScene = "Scene_0";
      }
      this.$router.replace({
        query: { ...this.$route.query, scene: newScene },
      });
      updateScene(newScene, []);
      this.$store.commit("setScene", newScene);
    },

    checkInArray(array, obj) {
      var ret = false;
      array.forEach(function (item) {
        if (item == obj) {
          ret = true;
        }
      });
      return ret;
    },

    doShowMsg() {
      var _this = this;
      this.showMsg = true;
      setTimeout(function () {
        _this.showMsg = false;
      }, 2000);
    },
    deleCollect(index) {
      // 判断当前是否展示再场景中,是的话删除在场景中的产品
      if (this.inArray.includes(index)) {
        this.icsClick(index);
      }
      // 选中状态
      for (let i = 0; i < this.inArray.length; i++) {
        if (this.inArray[i] > index) {
          this.inArray[i] -= 1;
        }
      }
      // 删除数据
      this.$emit("deleCollect", index);
      for (let key in this.positionArray) {
        if (this.positionArray[key] > index) {
          this.positionArray[key] = this.positionArray[key] - 1;
        } else if (this.positionArray[key] == index) {
          this.positionArray[key] = "";
        }
      }
    },
  },
  beforeDestroy() {
    this.$refs.selector.removeEventListener("mousedown", this.mousedown1Fn);
  },
};
</script>

<style scoped>
.dialog_scene {
  width: 180px;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 100px);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: #f3f3f3;
  padding: 10px;
  margin: 0px;
  font-size: 12px;
}
.dialog_scene p {
  opacity: 0.8;
}
.tabbaroption {
  position: absolute;
  bottom: 30px;
  padding-bottom: 10px;
  height: auto;
  width: 100%;
  margin: 0;
  /*  display: none */
  /*  animation: none; */
  pointer-events: all;
  box-sizing: border-box;
  border: 2px solid #ffffff80;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-image: linear-gradient(#0000000f, #ffffff14);
  overflow: hidden;
  /* filter: blur(5px); */
  /* background: white; */
  animation: upshow 0.6s 0s ease 1;
  animation-fill-mode: forwards;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.5);
  background-image: linear-gradient(#0000000f, #ffffff14); */
  background: url(../assets/options/mask.png);
  /* filter: blur(1px); */
  opacity: 0.75;
}

.inconect {
  position: relative;
  top: 0px;
  /* left: 2%; */
  height: 36px;
  width: 36px;
  pointer-events: all;
  cursor: pointer;
  user-select: none;
}
.inconect img {
  margin-left: 6px;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.ics-img {
  /* transform: rotateY(180deg); */
}
.delete {
  height: 36px;
  width: 36px;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.delete img {
  height: 60%;
  width: auto;
  right: 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.direction {
  background: transparent;
  width: 10px;
  height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.direction > img {
  height: 15px;
  width: 8px;
}
.selected {
  padding: 0px;
  width: 88%;
  overflow-y: scroll;
  overflow-x: scroll;
  height: 140px;
}
.litseletor {
  background-color: transparent;
  list-style-type: none;
  display: -webkit-box;
  padding: 0px;
  margin: 0px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  /* padding: 8.5px 0px; */
}

.litseletor::-webkit-scrollbar {
  display: none;
}
.selected::-webkit-scrollbar {
  display: none;
}
.slide {
  height: 66px;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  /* height: 130px; */
}
.color-selected {
  margin: 4px 6px;
  padding: 4px 0;
  width: 92px;
  height: 46px;
  /* border:1px solid blue; */
  border-radius: 10px;
  cursor: pointer;
  position: relative;

  /* 判断 */
  /* background:rgb(0 0 0 / 10%); */
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
}
.color-selected img {
  width: 100%;
  height: auto;
  object-fit: contain;
  /* border:1px solid red; */
  /* pointer-events: none; */
  user-select: none;
}

.fadeRotate-enter,
.fadeRotate-leave-to {
  /* //进入前，离开后 */
  opacity: 0;
  transform: scale(0) rotate(360deg);
}
.fadeRotate-enter-to,
.fadeRotate-leave {
  /* //进入后，离开前 */
  opacity: 1;
  transform: scale(1) rotate(0deg);
}
.fadeRotate-enter-active,
.fadeRotate-leave-active {
  transition: all 0.5s;
}

.del {
  position: absolute;
  bottom: 5px;
  right: 0;
  width: 15px !important;
  height: 15px !important;
  z-index: 10006;
}
.delmask {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  z-index: 10005;
  pointer-events: auto;
}
.allmask {
  position: absolute;
  width: 100%;
  height: 125px;
  background: gray;
  bottom: 18px;
  left: 0;
  z-index: 100;
  opacity: 0.1;
  pointer-events: none;
}
.tabbaroption {
  bottom: 45px;
}
</style>

<style lang="less" scoped>
.color-selected {
  animation: moveLeft 1s;
}
@keyframes moveLeft {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
</style>
