import { render, staticRenderFns } from "./Colorselect3.vue?vue&type=template&id=1ec45dd3&scoped=true&"
import script from "./Colorselect3.vue?vue&type=script&lang=js&"
export * from "./Colorselect3.vue?vue&type=script&lang=js&"
import style0 from "./Colorselect3.vue?vue&type=style&index=0&id=1ec45dd3&prod&lang=less&scoped=true&"
import style1 from "./Colorselect3.vue?vue&type=style&index=1&id=1ec45dd3&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ec45dd3",
  null
  
)

export default component.exports