import { render, staticRenderFns } from "./Places.vue?vue&type=template&id=6db70d25&scoped=true&"
import script from "./Places.vue?vue&type=script&lang=js&"
export * from "./Places.vue?vue&type=script&lang=js&"
import style0 from "./Places.vue?vue&type=style&index=0&id=6db70d25&prod&scoped=true&lang=css&"
import style1 from "./Places.vue?vue&type=style&index=1&id=6db70d25&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6db70d25",
  null
  
)

export default component.exports